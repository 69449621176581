import React, { useEffect, useState, useRef } from 'react';
import { Table, Container, Row, Col, Form, InputGroup, Pagination } from 'react-bootstrap';
import { IoIosSearch } from 'react-icons/io';
import { FaChevronLeft, FaChevronRight,FaSortUp, FaSortDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceHistory } from '../features/deviceHistorySlice';
import Navbar from './Navbar';


import { setValue, getValue } from '../utils/auth';
import './Style.css';
import moment from 'moment-timezone';

function DeviceHistory() {
  const dispatch = useDispatch();
  const isFetcing = useRef( false );
  const { devices, status, error } = useSelector((state) => state.deviceHistory);
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState('createddate');
  const [sortDirection, setSortDirection] = useState(false); 
  const usersPerPage = devices.pageSize;
  const totalPages = devices.totalPages;
  const pageIndex = (currentPage - 1) * usersPerPage;
  setValue('devicehistory_cur_page', currentPage);
 


 

// Format date-time using moment
const formatDateTime = (date) => {
  return moment(date).format('DD MMM YYYY, h:mm:ss A');
};

 useEffect(() => {
  
}, [dispatch]);

// Fetch device history 
  useEffect(() => {
    if ( !isFetcing.current ) {
      dispatch(fetchDeviceHistory({
        searchTerm:searchTerm,
        sort: sortDirection, 
        sortKey: sortKey 
      }));
      isFetcing.current = true
    }
  }, [dispatch, currentPage,sortKey, sortDirection, searchTerm, status]);

  // Update filtered devices when device data changes
  useEffect(() => {
    if (devices.userDevices) {
      setFilteredDevices(devices.userDevices);
    }
    
  }, [devices]);

  // Reset fetching state when status changes
  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcing.current = false
}, [status]);

// Filter devices based on search term
  useEffect(() => {
    if (devices.userDevices) {
      const results = devices.userDevices.filter(device =>
        device.contactUserDevices.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        device.contactUserDevices.mobilephone.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (searchTerm){
        setCurrentPage(1);
    setValue('devicehistory_cur_page', 1);
      }
      
      setFilteredDevices(results);
    }
  }, [searchTerm, devices]);

  // Get admin settings values for dynamic font sizes.
  setValue('labelFontSize', adminSettings.labelFontSize);
  setValue('iconFontSize', adminSettings.iconFontSize);
  setValue('tableHeadingFontSize', adminSettings?.properties?.tableHeadingFontSize);
  setValue('tableDetailsFontSize', adminSettings?.properties?.tableDetailsFontSize);
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);



  const tblHeadingFontSize = getValue('tableHeadingFontSize');
 const tblDetailsFontSize = getValue('tableDetailsFontSize');
 const hdngFontSize = getValue('headingFontSize');



 // Handle pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setValue('devicehistory_cur_page', pageNumber);
  };

   // Generate page numbers for pagination
  const startPage = Math.max(currentPage, 1);
  const endPage = Math.min(startPage + 2, totalPages);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

    // Handle sorting of table columns
  const handleSort = (key) => {
   
    if (sortKey === key) {
      setSortDirection(!sortDirection); 
    } else {
     
      setSortKey(key);
      setSortDirection(true);
    }
  
    
  
  };

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <h3 className="mb-3" style={{fontSize: hdngFontSize + "px"}}>Device History</h3>
        <Container className="bg-white" style={{ padding: "20px" }}>
          <Row className="align-items-center mb-3">
            <Col xs={12} md={4}>
              <Form className="d-flex search" role="search">
                <InputGroup>
                  <InputGroup.Text className="bg-white">
                    <IoIosSearch />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search by Name or Mobile"
                    className="text-center"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
              </Form>
            </Col>
          </Row>

          {status === 'loading' && <div>Loading...</div>}
          {status === 'failed' && <div>Error: {error}</div>}
          <div className="table-responsive">
            <Table striped borderless hover rounded>
              <thead>
                <tr>
                  <th style={{fontSize: tblHeadingFontSize + "px"}}>Sr. No</th>
                  <th
        className="text-start"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('name')}
      >
        Name
        <span className="ms-2 align-items-center">
        {sortKey === 'name' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
                  <th style={{fontSize: tblHeadingFontSize + "px"}}>Brand</th>
                  <th style={{fontSize: tblHeadingFontSize + "px"}}>Model</th>
                  <th style={{fontSize: tblHeadingFontSize + "px"}}>IMEI</th>
                  <th style={{fontSize: tblHeadingFontSize + "px"}}>Device Registration Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredDevices.map((entry, index) => (
                  <tr key={entry.id}>
                    <td style={{fontSize: tblDetailsFontSize + "px"}}>{pageIndex + index + 1}</td>
                    <td style={{fontSize: tblDetailsFontSize + "px"}}>{entry.contactUserDevices.name}</td>
                    <td style={{fontSize: tblDetailsFontSize + "px"}}>{entry['brand__c']}</td>
                    <td style={{fontSize: tblDetailsFontSize + "px"}}>{entry['model__c']}</td>
                    <td style={{fontSize: tblDetailsFontSize + "px"}}>{entry['imei__c']}</td>
                    <td style={{fontSize: tblDetailsFontSize + "px"}}> {formatDateTime(entry['createddate'])}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination className="justify-content-end mb-3 me-2">
            <Pagination.Prev
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaChevronLeft className="text-dark" />
            </Pagination.Prev>

            {pageNumbers.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => paginate(page)}
                className="ms-1 me-1"
              >
                {page}
              </Pagination.Item>
            ))}

            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight className="text-dark" />
            </Pagination.Next>
          </Pagination>
        </Container>
      </main>
    </div>
  );
}

export default DeviceHistory;
