// Imports
import React, { useState, useEffect } from 'react';
import { Table, Button, Container, OverlayTrigger, Tooltip, FormControl, Form,Row,Col } from 'react-bootstrap';
import { FaEdit, FaSave, FaHistory } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';
import { triggerToast } from '../utils/toast';
import { Link } from 'react-router-dom';
import { fetchMobileSetting, updateMobileSetting } from '../features/settingSlice';
import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getValue } from '../utils/auth';
import './Style.css';

// Mobile Component
function MobileApp() {
  const dispatch = useDispatch();
  const mobileSettings = useSelector((state) => state.setting.mobileSettings);
  const [isModifying, setIsModifying] = useState(null);
  const [values, setValues] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const [initialValues, setInitialValues] = useState({}); // eslint-disable-line
  const [isValid, setIsValid] = useState({});
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  const [hours, setHours] = useState("08");
 const [minutes, setMinutes] = useState("00");

// Fetching Admin Settings
  useEffect(() => {
    dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Fetching Mobile Settings
  useEffect(() => {
    dispatch(fetchMobileSetting());
  }, [dispatch]);

// Get admin settings values for dynamic font sizes.
   setValue('iconFontSize', adminSettings.iconFontSize);
  setValue('tableHeadingFontSize', adminSettings?.properties?.tableHeadingFontSize);
  setValue('tableDetailsFontSize', adminSettings?.properties?.tableDetailsFontSize);
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);


 
  const icnSize = getValue('iconFontSize');
  const tblHeadingFontSize = getValue('tableHeadingFontSize');
  const tblDetailsFontSize = getValue('tableDetailsFontSize');
  const hdngFontSize = getValue('headingFontSize');
  const subHdngFontSize = getValue('subHeadingFontSize')

  // Initialize form values from mobileSettings
  useEffect(() => {
    if (mobileSettings.length > 0) {
      const initialValues = mobileSettings.reduce((acc, setting) => {
        acc[setting.key] = setting.value ?? setting.defaultvalue;
        return acc;
      }, {});
      setValues(initialValues);
      setOriginalValues(initialValues);
      setInitialValues(initialValues);
      setIsValid((prev) => ({ ...prev, tokenExpirationHour: true }));



      const tokenExpiration = initialValues.tokenExpirationHour || "8h";

      const [initialHours, initialMinutes] = tokenExpiration.match(/\d+/g).map(Number);

      setHours(initialHours.toString().padStart(2, "0"));

      setMinutes(initialMinutes ? initialMinutes.toString().padStart(2, "0") : "00");
    }
  }, [mobileSettings]);


   // Validate values for certain settings

  useEffect(() => {
    const validity = Object.keys(values).reduce((acc, key) => {
      acc[key] = /^#([0-9A-Fa-f]{3}){1,2}$/.test(values[key]) || key !== 'backgroundColor';
      return acc;
    }, {});
    setIsValid(validity);
  }, [values]);

  // Handle click to modify or save settings
  const handleModifyClick = (key) => {

    if (isModifying === key) {
      let isValidValue = true;

      if (key === "tokenExpirationHour") {

        const hour = parseInt(hours, 10);

        const minute = parseInt(minutes, 10);

        if (isNaN(hour) || hour < 0 || hour > 23 || isNaN(minute) || minute < 0 || minute > 59) {

          isValidValue = false;

        }

      }



      if (isValidValue) {

        let valueToUpdate = values[key];

        if (key === "tokenExpirationHour") {

          valueToUpdate = `${hours}h ${minutes}m`;

        }

        setOriginalValues({ ...originalValues, [key]: valueToUpdate });

        setIsModifying(null);
        dispatch(updateMobileSetting({ [key]: valueToUpdate }))
          .then(() => {
            triggerToast("Settings updated successfully", 'success');
            
          })
          .catch(() => {
            triggerToast("Failed to update settings", 'error');
          });
      } else {
        setIsValid({ ...isValid, [key]: false });
        triggerToast("Invalid value", 'error');
      }
    } else {
      setIsModifying(key);
      setIsValid({ ...isValid, [key]: true }); 
    }
  };

   // Restore values to their original state
   const handleRestoreClick = (key) => {
    const setting = mobileSettings.find(s => s.key === key);
    
    if (setting) {
      const defaultValue = setting.defaultvalue;
  
      if (key === "tokenExpirationHour") {
        const tokenExpiration = defaultValue || "8h";
  
        const [restoredHours, restoredMinutes] = tokenExpiration.match(/\d+/g).map(Number);
        
        setHours(restoredHours.toString().padStart(2, "0"));
        setMinutes(restoredMinutes ? restoredMinutes.toString().padStart(2, "0") : "00");
  
        setValues({ ...values, [key]: `${restoredHours}h ${restoredMinutes || 0}m` });
      } else {
        setValues({ ...values, [key]: defaultValue });
      }
      
      setOriginalValues({ ...originalValues, [key]: defaultValue }); 
      triggerToast("Value restored to default", 'info');
    }
  };

   // Handle value changes for various settings
  const handleValueChange = (e, key) => {
    setValues({ ...values, [key]: e.target.value });
    if (key === "tokenExpirationHour") {

      const [hour, minute] = e.target.value.split(' ');

      setHours(hour.replace('h', '').padStart(2, '0'));

      setMinutes(minute ? minute.replace('m', '').padStart(2, '0') : '00');

    }

  };

// Handle hour and minute changes

  const handleHoursChange = (e) => {

    setHours(e.target.value);

  };



  const handleMinutesChange = (e) => {

    setMinutes(e.target.value);
  };

  const iconButtonStyle = {
    backgroundColor: '#3786C6',
    border: 'none',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
    color: 'white',
  };


  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <Container>
          <h3 className="mt-4" style={{fontSize: hdngFontSize + "px"}}>Mobile App Settings</h3>
          <Row className="mb-3">
            <Col xs={10}>
          
          <p  style={{fontSize: subHdngFontSize + "px"}}>Settings / Mobile App Settings</p>
          </Col>
          <Col xs={2}>
          <div className="d-flex">
            <div className="ms-auto">
          <Link to="/mobilehistory" className="text-decoration-none">
  <span className="text-primary me-3"  style={{fontSize:"20px"}} >History</span>
</Link>
</div>
</div>
          </Col>
          </Row>
          <Table responsive borderless className="rounded-2">
            <thead>
              <tr>
                <th className="text-center" style={{fontSize: tblHeadingFontSize + "px"}}>Sr. No.</th>
                <th className="text-center " style={{fontSize: tblHeadingFontSize + "px"}}>Label</th>
                <th className="text-center " style={{fontSize: tblHeadingFontSize + "px"}}>Field Name</th>
                <th className="text-center " style={{fontSize: tblHeadingFontSize + "px"}}>Value</th>
                <th className="text-center " style={{fontSize: tblHeadingFontSize + "px"}}>Action</th>
              </tr>
            </thead>
            <tbody>
              {mobileSettings.map((setting, index) => (
                <tr key={setting.id}>
                  <td className="text-center p-3" style={{fontSize: tblDetailsFontSize + "px"}}>{index + 1}</td>
                  <td className="text-center p-3" style={{fontSize: tblDetailsFontSize + "px"}}>{setting.label}</td>
                  <td className="text-center p-3" style={{fontSize: tblDetailsFontSize + "px"}}>{setting.key}</td>
                  <td className="text-center p-3" style={{ fontSize: tblDetailsFontSize + "px" }}>
  {isModifying === setting.key ? (
    setting.key === "tokenExpirationHour" ? (
      <div className="d-flex justify-content-center">
        <Form.Control
          as="select"
          value={hours}
          onChange={handleHoursChange}
          className="me-2"
        >
          {Array.from({ length: 24 }, (_, i) => (
            <option key={i} value={i.toString().padStart(2, "0")}>
              {i.toString().padStart(2, "0")}h
            </option>
          ))}
        </Form.Control>
        <Form.Control
          as="select"
          value={minutes}
          onChange={handleMinutesChange}
        >
          {["00", "15", "30", "45"].map(minute => (
            <option key={minute} value={minute}>
              {minute}m
            </option>
          ))}
        </Form.Control>
      </div>
    ) : setting.key === "theme" ? (
      <Form.Control
        as="select"
        value={values[setting.key]}
        onChange={(e) => handleValueChange(e, setting.key)}
        className="mx-auto"
      >
        <option value="dark">Dark</option>
        <option value="light">Light</option>
      </Form.Control>
    ) : ["enableNotifications", "showStatusBar"].includes(setting.key) ? (
      <Form.Control
        as="select"
        value={values[setting.key]}
        onChange={(e) => handleValueChange(e, setting.key)}
        className="mx-auto"
      >
        <option value="true">True</option>
        <option value="false">False</option>
      </Form.Control>
    ) : setting.key === "backgroundColor" ? (
      <div className="d-flex align-items-center justify-content-center">
        <Form.Control
          type="color"
          value={values[setting.key]}
          onChange={(e) => handleValueChange(e, setting.key)}
          style={{ width: '40px', height:'40px' }}
          className="me-2"
        />
        <Form.Control
          type="text"
          value={values[setting.key]}
          onChange={(e) => {
            // Ensure the input value is a valid color
            const colorValue = e.target.value;
            if (/^#[0-9A-Fa-f]{6}$/.test(colorValue) || /^#[0-9A-Fa-f]{3}$/.test(colorValue)) {
              handleValueChange(e, setting.key);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleModifyClick(setting.key);
            }
          }}
          className="ms-2"
          style={{ width: '100px' }}
        />
      </div>
    ) : ["maxCash", "defaultZoom", "fontSize"].includes(setting.key) ? (
      <Form.Control
        type="number"
        value={values[setting.key]}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) {
            handleValueChange(e, setting.key);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleModifyClick(setting.key);
          }
        }}
        min="0"
        isInvalid={!isValid[setting.key]}
        className="mx-auto"
      />
    ) : (
      <FormControl
        type="text"
        value={values[setting.key]}
        onChange={(e) => handleValueChange(e, setting.key)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleModifyClick(setting.key);
          }
        }}
        style={{ width: 'auto' }}
        isInvalid={!isValid[setting.key]}
        className="mx-auto"
      />
    )
  ) : (
    setting.key === "tokenExpirationHour" ? (
      `${hours}h ${minutes}m`
    ) : setting.key === "theme" ? (
      values[setting.key] === 'dark' ? 'Dark' : 'Light'
    ) : setting.key === "backgroundColor" ? (
      <div className="d-flex align-items-center justify-content-center">
        <div
          style={{
            width: '30px',
            height: '30px',
            backgroundColor: values[setting.key],
            marginRight: '8px' ,
            border: "1px solid grey",
            borderRadius: "5px"
          }}
        />
        <span>{values[setting.key]}</span>
      </div>
    ) : (
      values[setting.key]
    )
  )}
</td>













                  <td>

                    <div className="d-flex pt-2">

                      <OverlayTrigger

                        placement="top"

                        overlay={<Tooltip>{isModifying === setting.key ? 'Save' : 'Modify'}</Tooltip>}

                      >

                        <Button

                          style={iconButtonStyle}

                          onClick={() => handleModifyClick(setting.key)}

                        >

                          <span className="icon-wrapper" style={{ color: adminSettings.iconColor, fontSize: icnSize + "px" }}>

                            {isModifying === setting.key ? <FaSave /> : <FaEdit />}

                          </span>

                        </Button>

                      </OverlayTrigger>

                      <OverlayTrigger

                        placement="top"

                        overlay={<Tooltip>Restore</Tooltip>}

                      >

                        <Button style={iconButtonStyle} className="ms-2" onClick={() => handleRestoreClick(setting.key)}>

                          <span style={{ color: adminSettings.iconColor, fontSize: icnSize + "px" }} className="icon-wrapper">

                            <FaHistory />

                          </span>

                        </Button>

                      </OverlayTrigger>

                    </div>

                  </td>

                </tr>

              ))}

            </tbody>

          </Table>

        </Container>

      </main>

    </div>

  );

}



export default MobileApp;
