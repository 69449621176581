// imports
import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../features/authSlice';
import { triggerToast } from '../utils/toast';


function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth); // eslint-disable-line

    // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(forgotPassword({ email }))
      .unwrap()
      .then(() => {
        triggerToast("Reset password email sent.", 'success');
        setTimeout(() => navigate('/setnewpassword'), 3000); 
      })
      .catch((err) => {
        triggerToast(err || "Failed to send reset password email.", 'error');
      });
  };

  return (
    <Container fluid className="login-container d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <h1 className="p-5 text-center text-white">Admin Panel</h1>
      <Container className="p-5 border rounded-2 shadow-lg bg-white" style={{ maxWidth: '600px', width: '100%' }}>
        <h2 className="mb-5">Forgot Password</h2>
        {status === 'loading' && <Alert variant="info">Sending reset link...</Alert>}
       
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email" className="mb-3">
            <Form.Label>Enter Your Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-50 mt-4 d-block mx-auto" disabled={status === 'loading'}>
            Continue
          </Button>
          <Link to="/login" className="back-to-login mt-3 d-block text-center text-decoration-none">
            <FaArrowLeft className="me-2" />
            Back to Login
          </Link>
        </Form>
      </Container>
    </Container>
  );
}

export default ForgotPassword;
