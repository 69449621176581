// Imports
import React, { useEffect,useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMobileSettingsHistory } from '../features/mobileHistorySlice'; 
import Navbar from './Navbar';
import { Container, Row, Col, ListGroup, Pagination,Spinner } from 'react-bootstrap';
import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getValue } from '../utils/auth';
import { FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import moment from 'moment-timezone';

// Mobile History Components
const MobileHistory = () => {
  const isFetcingUser = useRef( false );
  const dispatch = useDispatch();
  const { mobileSettingsHistory, status, error } = useSelector((state) => state.mobileHistory);
  const adminSettings = useSelector((state) => state.setting.adminSettings);

  const usersPerPage = 10; 
  const totalPages = mobileSettingsHistory?.totalPages;
  setValue('mobilehistory_page_size', usersPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  
  setValue('mobilehistory_cur_page', currentPage);


// Fetch mobile settings history data
  useEffect(() => {
    if ( !isFetcingUser.current ) {
    dispatch(fetchMobileSettingsHistory());
    isFetcingUser.current = true
    }
  }, [dispatch,status,currentPage]);

  // Fetch Admin Settings
  useEffect(() => {
    dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Update fetching status based on request status
  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcingUser.current = false
}, [status]);

    // Handle pagination
    const paginate = (pageNumber) => {
     
      setCurrentPage(pageNumber);
      setValue('adminhistory_cur_page', pageNumber);
    }
    // Calculate the range of pages to display
    const startPage = Math.max(currentPage, 1);
    const endPage = Math.min(startPage + 2, totalPages);
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

 // Get admin settings values for dynamic font sizes.
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);


  const hdngFontSize = getValue('headingFontSize');
  const subHdngFontSize = getValue('subHeadingFontSize')

  const historyList = mobileSettingsHistory?.mobileSettingsHistory || [];

  
// Format datetime for display
  const formatDateTime = (date) => {
    return moment(date).format('DD MMM YYYY, h:mm:ss A');
  };

  // Filter the settings history to show only changed settings
  let filterItems = (historyList) => { // eslint-disable-line
    let new_array = [];
   // eslint-disable-next-line array-callback-return
    (historyList || []).map((item, index) => {
      
    
        let previoussetting = item.previoussetting;
        let currentsetting = item.currentsetting;
        
        if(!new_array[index]) new_array[index] = [];
        if(!new_array[index]['currentsetting']) new_array[index]['currentsetting'] = [];
        if(!new_array[index]['previoussetting']) new_array[index]['previoussetting'] = [];
        if(!new_array[index]['currentsetting']['properties']) new_array[index]['currentsetting']['properties'] = [];
        if(!new_array[index]['previoussetting']['properties']) new_array[index]['previoussetting']['properties'] = [];
        
        for(var key in currentsetting){
          let settingVal = '';
          if(key==='properties'){
            for(var propKey in currentsetting.properties){
              let propSettingVal = currentsetting.properties[propKey];
             
              if(previoussetting.properties[propKey] && (propSettingVal !== previoussetting.properties[propKey]) ){
               
                new_array[index]['previoussetting']['properties'][propKey]=previoussetting.properties[propKey];
                new_array[index]['currentsetting']['properties'][propKey]=propSettingVal;
              }
            }
          }else{
            if(key !=='default_values'){
              settingVal = currentsetting[key];
              if(previoussetting[key] && (settingVal !== previoussetting[key]) ){
                new_array[index]['previoussetting'][key]=previoussetting[key];
                new_array[index]['currentsetting'][key]=settingVal;
                
              }
            }
          }
          
        }
        if( (Object.keys(new_array[index]['previoussetting']).length < 2) || (Object.keys(new_array[index]['previoussetting']['properties']).length <1) ){
          console.log('previoussetting', Object.keys(new_array[index]['previoussetting']));
          delete new_array[index]['currentsetting'];
          delete new_array[index]['previoussetting'];
        }
    });
    return new_array;
};

let filteredSettingHistory = [];


filteredSettingHistory = historyList;

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
      <Container className="mobile-history mt-3">
        <div className="mt-4 mb-4">
      <h2  style={{fontSize: hdngFontSize + "px"}}>Mobile Settings History</h2>
      <p>Settings / Mobile App Settings / History</p></div>
      {status === 'loading' ? (
            <div className="text-center mt-5">
              <Spinner animation="border" variant="primary" />
              <p>Loading...</p>
            </div>
          ) : status === 'failed' ? (
            <div className="text-center mt-5 text-danger">
              <p>{error}</p>
            </div>
          ) : (
          <Row>
            <Col md={6}>
              <h2 style={{fontSize: subHdngFontSize + "px"}}>Previous Settings</h2>
              <ListGroup>
              {(filteredSettingHistory?.length>0)?(
                  (filteredSettingHistory || []).map((item, index) => (
                  <ListGroup.Item key={index}>
                    {item.createddate ? (<p><strong>Date:</strong> {formatDateTime(item.createddate)  || 'N/A'}</p>):''}
                    {item.adminDetail?.email ? (
                      <p><strong>Last Modified By:</strong> {item.adminDetail?.email || 'N/A'}</p> 
                    ):''}
                    {item.previoussetting?.key ? (
                      <p className={(item.previoussetting?.key !== item.currentsetting?.key)?'yelloBg':''}><strong>Key:</strong> {item.previoussetting?.key}</p>
                    ):''}
                     {item.previoussetting?.label ? (
                      <p className={(item.previoussetting?.label!== item.currentsetting?.label)?'yelloBg':''}><strong>Label:</strong> {item.previoussetting?.label}</p>
                    ):''}
                     {item.previoussetting?.value ? (
                      <p className={(item.previoussetting?.value!== item.currentsetting?.value)?'yelloBg':''}><strong>Value:</strong> {item.previoussetting?.value}</p>
                    ):''}
                     {item.previoussetting?.defaultvalue ? (
                      <p className={(item.previoussetting?.defaultvalue!== item.currentsetting?.defaultvalue)?'yelloBg':''}><strong>Default Value:</strong> {item.previoussetting?.defaultvalue}</p>
                    ):''}
                    
                  </ListGroup.Item>
                ))):(
                  <ListGroup.Item>
                    <p><strong>No settings found</strong> </p>
                    </ListGroup.Item>
                )}
              </ListGroup>
            </Col>

            <Col md={6}>
              <h2 style={{fontSize: subHdngFontSize + "px"}}>Current Settings</h2>
              <ListGroup>
              {(filteredSettingHistory?.length>0)?(
                  (filteredSettingHistory || []).map((item, index) => (
                  <ListGroup.Item key={index}>
                     {item.createddate ? (<p><strong>Date:</strong> {formatDateTime(item.createddate)  || 'N/A'}</p>):''}
                    {item.adminDetail?.email ? (
                     <p><strong>Last Modified By:</strong> {item.adminDetail?.email || 'N/A'}</p> 
                      ):''}
                      {item.currentsetting?.key ? (
                      <p className={(item.previoussetting?.key !== item.currentsetting?.key)?'yelloBg':''}><strong>Key:</strong> {item.currentsetting?.key || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.label ? (
                      <p className={(item.previoussetting?.label !== item.currentsetting?.label)?'yelloBg':''}><strong>Label:</strong> {item.currentsetting?.label || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.value ? (
                      <p className={(item.previoussetting?.value !== item.currentsetting?.value)?'yelloBg':''}><strong>Value:</strong> {item.currentsetting?.value || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.defaultvalue ? (
                      <p className={(item.previoussetting?.defaultvalue !== item.currentsetting?.defaultvalue)?'yelloBg':''}><strong>Default Value:</strong> {item.currentsetting?.defaultvalue || 'N/A'}</p>
                      ):''}
                    
                  </ListGroup.Item>
                ))):(
                  <ListGroup.Item>
                    <p><strong>No settings found</strong> </p>
                    </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
          )}
          <Pagination className="justify-content-end mt-3 mb-3 me-2">
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft className="text-dark" />
                </Pagination.Prev>

                {pageNumbers.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => paginate(page)}
                    className="ms-1 me-1"
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight className="text-dark" />
                </Pagination.Next>
              </Pagination>
        </Container>
      </main>
    </div>
  );
};

export default MobileHistory;
