// Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col ,Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { updateAdminSetting, resetAdminSettings } from '../features/settingSlice';
import { triggerToast } from '../utils/toast';
import { getValue,setValue } from '../utils/auth';
import './Style.css';
import Navbar from './Navbar';


function AdminPanelSettings() {
  const dispatch = useDispatch();
  
  const [tokenExpirationHour, setTokenExpirationHour] = useState(1);
  const [tokenExpirationMinute, setTokenExpirationMinute] = useState(0);
  const [logo, setLogo] = useState(null);
  const [sideColor, setSideColor] = useState('#3786C6');
  const [primaryButtonColor, setPrimaryButtonColor] = useState('#3786C6'); 
  const [secondaryButtonColor, setSecondaryButtonColor] = useState('#6c757d');
  const [labelFontSize, setLabelFontSize] = useState(16);
  const [iconColor, setIconColor] = useState('#3786C6');
  const [iconSize, setIconSize] = useState(20);
  const [tableHeadingFontSize, setTableHeadingFontSize] = useState(16);
  const [tableDetailsFontSize, setTableDetailsFontSize] = useState(14);
  const [headingFontSize, setHeadingFontSize] = useState(24);
  const [subHeadingFontSize, setSubHeadingFontSize] = useState(20);
  const [showResetModal, setShowResetModal] = useState(false);
  
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  const adminSettingUpdate = useSelector((state) => state.setting.adminSettingUpdate);

  useEffect(() => {
    
 }, [dispatch,adminSettingUpdate]);

 // Admin settings values for dynamic font sizes.
 setValue('labelFontSize', adminSettings.labelFontSize);
  setValue('iconFontSize', adminSettings.iconFontSize);
  setValue('tableHeadingFontSize', adminSettings?.properties?.tableHeadingFontSize);
  setValue('tableDetailsFontSize', adminSettings?.properties?.tableUserDetailsFontSize);
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);

 const lblFontSize = getValue('labelFontSize' );

 const hdngFontSize = getValue('headingFontSize');
 const subHdngFontSize = getValue('subHeadingFontSize')
 


// Handle showing and closing of the reset modal
const handleShowResetModal = () => setShowResetModal(true);
 const handleCloseResetModal = () => setShowResetModal(false);


// Update settings form fields based on admin settings 
  useEffect(() => {
    if (adminSettings) {
      setSideColor(adminSettings.sideMenuColor || '#3786C6');
    setPrimaryButtonColor(adminSettings?.properties?.primaryButtonColor || '#3786C6');
    setSecondaryButtonColor(adminSettings?.properties?.secondaryButtonColor || '#6c757d');
    setLabelFontSize(parseInt(adminSettings.labelFontSize, 10) || 16);
    setIconColor(adminSettings.iconColor || '#3786C6');
    setIconSize(parseInt(adminSettings.iconFontSize, 10) || 20);
    setLogo(adminSettings.logoImage || null);
    setTableHeadingFontSize(parseInt(adminSettings?.properties?.tableHeadingFontSize, 10) || 16);
    setTableDetailsFontSize(parseInt(adminSettings?.properties?.tableDetailsFontSize, 10) || 14);
    setHeadingFontSize(parseInt(adminSettings?.properties?.headingFontSize, 10) || 24);
    setSubHeadingFontSize(parseInt(adminSettings?.properties?.subHeadingFontSize, 10) || 20);
    const tokenExpiration = adminSettings.tokenExpirationHour || '1hr';
      const [hourPart, minutePart] = tokenExpiration.split('hr');
      setTokenExpirationHour(parseInt(hourPart, 10) || 1);
      const minutes = minutePart ? minutePart.replace('min', '') : '0';
      setTokenExpirationMinute(parseInt(minutes, 10) || 0);
    }
  }, [adminSettings]);

  

  // Handle Logo Change
  const handleLogoChange = (event) => {
   
    let logoFile = (event.target.files[0]);
    if (logoFile) {
      let fileReader = new FileReader();
        fileReader.readAsDataURL(logoFile);
 
        fileReader.onload = (event) => {
          setLogo(event.target.result);
        }
    }
    
  };
  
// Handle Side Color Change
  const handleSideColorChange = (event) => {
    setSideColor(event.target.value);
  };

// Handle Primary Button Color Change
  const handlePrimaryButtonColorChange = (event) => {
    setPrimaryButtonColor(event.target.value); 
  };

  // Handle Secondary Button Color Change
  const handleSecondaryButtonColorChange = (event) => {
    setSecondaryButtonColor(event.target.value);
  };

  // Handle label Font Size
  const handleLabelFontSizeChange = (event) => {
    setLabelFontSize(event.target.value);
  };
  
  // Handle Icon Color Change
  const handleIconColorChange = (event) => {
    setIconColor(event.target.value);
  };

  // Handle Icon Font Size Change
  const handleIconFontSizeChange = (event) => {
    setIconSize(event.target.value);
  };

  // Handle Table Heading Font Size Change
  const handleTableHeadingFontSizeChange = (event) => {
    setTableHeadingFontSize(event.target.value);
  };

  // Handle Table Details Font Size Change
  const handleTableDetailsFontSizeChange = (event) => {
    setTableDetailsFontSize(event.target.value);
  };

  // Handle Heading Font Size Change
  const handleHeadingFontSizeChange = (event) => {
    setHeadingFontSize(event.target.value);
  };

  // Handle Subheading Font Size Change
  const handleSubHeadingFontSizeChange = (event) => {
    setSubHeadingFontSize(event.target.value);
  };

  
  //  handle Reset
  const handleResetSettings = () => {
    dispatch(resetAdminSettings()).then((response) => {
      const resetData = response.payload.updatedSettings;
      const defaultData = resetData.default_values;
  
      setSideColor(resetData.sideMenuColor || defaultData.sideMenuColor || '#3786C6');
      setPrimaryButtonColor(resetData?.properties?.primaryButtonColor || defaultData?.properties?.primaryButtonColor || '#3786C6');
      setSecondaryButtonColor(resetData?.properties?.secondaryButtonColor || defaultData?.properties?.secondaryButtonColor || '#6c757d');
      setLabelFontSize(parseInt(resetData.labelFontSize, 10) || parseInt(defaultData.labelFontSize, 10) || 16);
      setIconColor(resetData.iconColor || defaultData.iconColor || '#fafafa');
      setIconSize(parseInt(resetData.iconFontSize, 10) || parseInt(defaultData.iconFontSize, 10) || 20);
      setLogo(resetData.logoImage || defaultData.logoImage || null);
      setTableHeadingFontSize(parseInt(resetData?.properties?.tableHeadingFontSize, 10) || parseInt(defaultData?.properties?.tableHeadingFontSize, 10) || 16);
      setTableDetailsFontSize(parseInt(resetData?.properties?.tableDetailsFontSize, 10) || parseInt(defaultData?.properties?.tableDetailsFontSize, 10) || 14);
      setHeadingFontSize(parseInt(resetData?.properties?.headingFontSize, 10) || parseInt(defaultData?.properties?.headingFontSize, 10) || 24);
      setSubHeadingFontSize(parseInt(resetData?.properties?.subHeadingFontSize, 10) || parseInt(defaultData?.properties?.subHeadingFontSize, 10) || 16);
      const tokenExpiration = resetData.tokenExpirationHour || defaultData.tokenExpirationHour || '1hr';
      const [hourPart, minutePart] = tokenExpiration.split('hr');
      setTokenExpirationHour(parseInt(hourPart, 10) || 1);
      const minutes = minutePart ? minutePart.replace('min', '') : '0';
      setTokenExpirationMinute(parseInt(minutes, 10) || 0);
      handleCloseResetModal();
      triggerToast('Settings have been reset to default values.', 'success');
      window.location.reload();
    });
  };

  // Ensure tokenExpirationHour and tokenExpirationMinute are numbers
  const handleTokenExpirationHourChange = (event) => setTokenExpirationHour(parseInt(event.target.value, 10) || 0);
  const handleTokenExpirationMinuteChange = (event) => setTokenExpirationMinute(parseInt(event.target.value, 10) || 0);

     // Handle form submission logic
  const handleSubmit = (event) => {
    event.preventDefault();
 
    let requestBody = {
      tokenExpirationHour: `${tokenExpirationHour}hr${tokenExpirationMinute}min`,
     
      "sideMenuColor":sideColor,
       
      "labelFontSize":labelFontSize,
      "iconColor":iconColor,
      "iconFontSize":iconSize,
      "logoImage":logo,
      
      "properties": {
      "tableHeadingFontSize": tableHeadingFontSize,
      "tableDetailsFontSize": tableDetailsFontSize,
      "headingFontSize": headingFontSize,
      "subHeadingFontSize": subHeadingFontSize,
      "primaryButtonColor": primaryButtonColor,
      "secondaryButtonColor": secondaryButtonColor,

      }

  };
    dispatch(updateAdminSetting(requestBody));
    triggerToast("Details saved successfully", 'success');
    window.location.reload();
  };
  

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content">
      <Container> 
      <h2 className=" mt-4" style={{fontSize: hdngFontSize + "px"}}>Admin Panel Theme Settings</h2>
      <Row className="mb-3">
        <Col xs={10}>
      <p  style={{fontSize: subHdngFontSize + "px"}}>Here you can change theme settings to changes enhance look and feel of admin  portal</p>
      </Col>
      <Col xs={2}>
      <div className="d-flex">
            <div className="ms-auto">
          <Link to="/adminhistory" className="text-decoration-none">
  <span className="text-primary me-3" style={{fontSize:"20px"}} >History</span>
</Link>
</div>
</div>
    </Col>
    </Row>
      <Form
        onSubmit={handleSubmit}
        style={{
          borderRadius: '8px',
          padding: '40px',
          backgroundColor: '#fff',
        }}
      >
        {/* Form Fields */}
        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group controlId="formLogo">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Change Admin Logo</Form.Label>
              <Form.Control 
                type="file" 
                label="Upload"
                custom
                onChange={handleLogoChange}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="formSideColor">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Choose Side Menu Color</Form.Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Control 
                  type="color" 
                  value={sideColor} 
                  onChange={handleSideColorChange} 
                  style={{
                    width: '50px',
                    height: '40px',
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem 0 0 0.25rem',
                    marginRight: '-1px',
                  }}

                
                 
                />
                <Form.Control 
                  type="text" 
                  value={sideColor} 
                  onChange={handleSideColorChange}
                  style={{
                    flexGrow: 1,
                    borderRadius: '0 0.25rem 0.25rem 0',
                  }}
                     readOnly
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
        <Col sm={6}>
                <Form.Group controlId="formHeadingFontSize">
                  <Form.Label style={{ fontSize: lblFontSize + "px" }}>Change Heading Font Size</Form.Label>
                  <Form.Control 
                    type="number" 
                    value={headingFontSize} 
                    onChange={handleHeadingFontSizeChange}
                   
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="formSubHeadingFontSize">
                  <Form.Label style={{ fontSize: lblFontSize + "px" }}>Change Sub-Heading Font Size</Form.Label>
                  <Form.Control 
                    type="number" 
                    value={subHeadingFontSize} 
                    onChange={handleSubHeadingFontSizeChange}
                    
                  />
                </Form.Group>
              </Col>
        </Row>

        <Row className="mb-3">
          <Col sm={6}>
            <Form.Group controlId="formMenuColorformPrimaryButtonColor">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Choose Primary Button Color</Form.Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Control 
                  type="color" 
                  value={primaryButtonColor}
                  onChange={handlePrimaryButtonColorChange} 
                  style={{
                    width: '50px',
                    height: '40px',
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem 0 0 0.25rem',
                    marginRight: '-1px',
                
                  }}
                />
                <Form.Control 
                  type="text" 
                  value={primaryButtonColor}
                  onChange={handlePrimaryButtonColorChange}
                  style={{
                    flexGrow: 1,
                    borderRadius: '0 0.25rem 0.25rem 0',
                  }}
                  readOnly
                />
              </div>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="formSecondaryButtonColor">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Choose Secondary Button Color</Form.Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Control 
                  type="color" 
                  value={secondaryButtonColor}
                  onChange={handleSecondaryButtonColorChange} 
                  style={{
                    width: '50px',
                    height: '40px',
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem 0 0 0.25rem',
                    marginRight: '-1px',
                
                  }}
                />
                <Form.Control 
                  type="text" 
                  value={secondaryButtonColor}
                      onChange={handleSecondaryButtonColorChange}
                  style={{
                    flexGrow: 1,
                    borderRadius: '0 0.25rem 0.25rem 0',
                  }}
                  readOnly
                />
              </div>
            </Form.Group>
          </Col>

          
        </Row >
        <Row className="mb-3">
        
          <Col sm={6}>
            <Form.Group controlId="formIconColor">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Choose Icon Color</Form.Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Control 
                  type="color" 
                  value={iconColor} 
                  onChange={handleIconColorChange} 
                  style={{
                    width: '50px',
                    height: '40px',
                    border: '1px solid #ced4da',
                    borderRadius: '0.25rem 0 0 0.25rem',
                    marginRight: '-1px',
                  }}
                />
                <Form.Control 
                  type="text" 
                  value={iconColor} 
                  onChange={handleIconColorChange}
                  style={{
                    flexGrow: 1,
                    borderRadius: '0 0.25rem 0.25rem 0',
                  }}
                  readOnly
                />
              </div>
            </Form.Group>
          </Col>
          <Col sm={6}>
          
          <Form.Group controlId="formIconFontSize">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}> Change Icon Font Size</Form.Label>
              <Form.Control 
                type="number" 
                value={iconSize} 
                onChange={handleIconFontSizeChange}
                defaultValue={16}
                
              />
            </Form.Group>
          </Col>
          </Row>

        <Row className="mb-3">
        
         
          <Col sm={6}>
                <Form.Group controlId="formTableHeadingFontSize">
                  <Form.Label style={{ fontSize: lblFontSize + "px" }}>Change Table Heading Font Size</Form.Label>
                  <Form.Control 
                    type="number" 
                    value={tableHeadingFontSize} 
                    onChange={handleTableHeadingFontSizeChange}
                   
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="formTableUserDetailFontSize">
                  <Form.Label style={{ fontSize: lblFontSize + "px" }}>Change User Details Font Size</Form.Label>
                  <Form.Control 
                    type="number" 
                    value={tableDetailsFontSize} 
                    onChange={handleTableDetailsFontSizeChange}
                    
                  />
                </Form.Group>
              </Col>
        </Row>
       

            <Row className="mb-3">
            <Col sm={6}>
            <Form.Group controlId="formLabelFontSize">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Change Label Font Size</Form.Label>
              <Form.Control 
                type="number" 
                value={labelFontSize} 
                onChange={handleLabelFontSizeChange}
                defaultValue={16}
                
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
          <Form.Group controlId="formTokenExpiration">
                  <Form.Label style={{ fontSize: lblFontSize + "px" }}>Change Token Expiration Hour</Form.Label>
                  <div className="d-flex">
                  <Form.Control 
                  as="select" 
                  value={tokenExpirationHour} 
                  onChange={handleTokenExpirationHourChange}
                >
                  {[...Array(24).keys()].map(i => (
                    <option key={i} value={i}>{i} hour{ i !== 1 ? 's' : '' }</option>
                  ))}
                </Form.Control>

                <Form.Control 
                  as="select" 
                  value={tokenExpirationMinute} 
                  onChange={handleTokenExpirationMinuteChange}
                  className="ms-2"
                >
                  {[0, 15, 30, 45].map(min => (
                    <option key={min} value={min}>{min} minute{ min !== 1 ? 's' : '' }</option>
                  ))}
                </Form.Control>
                  </div>
                </Form.Group>
                </Col>
            </Row>

           
           
           <div className="d-flex justify-content-center w-100">
             <Button className="w-30 w-sm-50 w-md-25 mt-4 me-4" onClick={handleShowResetModal}  style={{ backgroundColor: adminSettings?.properties?.secondaryButtonColor }} >
               Reset
             </Button>
             <Button variant="primary" type="submit" className="w-30 w-sm-50 w-md-25 mt-4"  style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} >
               Submit
             </Button>
           </div>
       
      </Form>
    </Container>
    <Modal show={showResetModal} onHide={handleCloseResetModal} centered>
         <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center' }}>
           <Modal.Title style={{ flex: 1, textAlign: 'center', fontSize: hdngFontSize + "px" }}>Confirm Reset</Modal.Title>
         </Modal.Header>
         <Modal.Body>Are you sure you want to reset the Admin Panel Theme Settings to their default values?</Modal.Body>
         <Modal.Footer>
                    <div className="text-center mx-auto">
            <Button  onClick={handleResetSettings} className='me-3' style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }}>
             Reset
           </Button>
           <Button style={{ backgroundColor: adminSettings?.properties?.secondaryButtonColor }} onClick={handleCloseResetModal}>
             Cancel
           </Button>
           
           </div>
          
         </Modal.Footer>
       </Modal>
      </main>
    </div>
  );
}

export default AdminPanelSettings;