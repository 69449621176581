// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser ,getValue} from '../utils/auth';

// Base URL for API requests

const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  mobileSettingsHistory: [],
  status: 'idle',
  error: null,
};

// Thunk for fetching mobile settings history
export const fetchMobileSettingsHistory = createAsyncThunk(
  'mobile/fetchMobileSettingsHistory',
  async () => {
    let pageNum = getValue('mobilehistory_cur_page')??1;
  let size = getValue('mobilehistory_page_size')??10;
    try {
      const response = await axios.get(`${baseURL}/api/adminSetting/getMobileSettingsHistory?page=${pageNum}&pageSize=${size}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 400) {
        logoutUser();
      }
      throw error;
    }
  }
);

// Slice for managing mobile settings history state
const mobileHistorySlice = createSlice({
  name: 'mobileHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMobileSettingsHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMobileSettingsHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mobileSettingsHistory = action.payload;
      })
      .addCase(fetchMobileSettingsHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if (action.error.code === "ERR_BAD_REQUEST") {
          logoutUser();
        }
      });
  },
});

export default mobileHistorySlice.reducer;
