// Imports
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from "@auth0/auth0-react";
import { adminLogin } from '../features/authSlice';
import googleLogo from './images/googlelogo.jpeg'; 

import './Style.css';
function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);

  
  const {  isAuthenticated, getIdTokenClaims, user, loginWithRedirect } = useAuth0();
   // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(adminLogin({ email: username, password })).then((action) => {
      if (adminLogin.fulfilled.match(action)) {
        navigate('/home');
        window.location.reload();
      }
    });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  useEffect(()=>{
   
    if(isAuthenticated){
      getIdTokenClaims().then(claims=>{
        let access_token = claims.__raw;
        
        dispatch(adminLogin({ email: user.email, sso_token: access_token })).then((action) => {
          if (adminLogin.fulfilled.match(action)) {
            navigate('/home');
            window.location.reload();
          }
        });
      });
      
      
    }else{
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isAuthenticated])
  
  
  

  return (
    <Container fluid className="login-container d-flex flex-column align-items-center justify-content-center">
      <h1 className="p-5 text-center text-white">Admin Panel</h1>
      <div className="login-box border rounded-4 shadow-lg pb-3">
        <h2 className="mb-4">Login</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="username" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Email Address"
              required
            />
          </Form.Group>
          <Form.Group controlId="password" className="mb-3 position-relative">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              style={{ paddingRight: '2.5rem' }} 
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              className="mt-3"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          {error && (
            <div className="text-danger mb-3">
              {error.message || 'Invalid credentials. Please try again.'}
            </div>
          )}
          <Row>
            <Col className="text-end">
              <Link to="/forgotpassword" className="text-decoration-none">
                <i>Forgot password?</i>
              </Link>
            </Col>
          </Row>
          <Button
            variant="primary"
            type="submit"
            className="w-50 mt-3 text-center d-block mx-auto"
          >
            {status === 'loading' ? 'Loading...' : 'Login'}
          </Button>
        </Form>
        <div className="text-center mt-3 mb-2">Or</div>
        <div className="d-flex justify-content-center mt-2 ">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
          href="#"
          onClick={() => loginWithRedirect()}
            className="me-3 d-flex align-items-center text-decoration-none rounded-pill border p-2 "
          >
            <img
              src={googleLogo}
              alt="Google Logo"
              style={{ width: '20px', height: '20px' }}
              className="me-2"
            />{' '}
            Continue with Google
          </a>
          
        </div>
      </div>
    </Container>
  );
}

export default Login;
