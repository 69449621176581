// Imports
import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Navbar from './Navbar';
import { triggerToast } from '../utils/toast';
import { changePassword } from '../features/authSlice';
import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getValue } from '../utils/auth';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
 ;

  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth); // eslint-disable-line
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  
  // Fetch admin settings on component mount
  useEffect(() => {
    dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Get admin settings values for dynamic font sizes.
  setValue('labelFontSize', adminSettings.labelFontSize);
 
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  // setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);



 const hdngFontSize = getValue('headingFontSize');

 const lblFontSize = getValue('labelFontSize' );

    // Toggle functions
    const toggleOldPasswordVisibility = () => {
      setShowOldPassword(!showOldPassword)
    };
    const toggleNewPasswordVisibility = () => {
      setShowNewPassword(!showNewPassword);
    }
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword)
    };
 
 // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      triggerToast("New Password and Confirm Password do not match.", 'error');
      
      return;
    }

 


    // Dispatch changePassword action with required data
    dispatch(changePassword({ oldPassword, newPassword }))
      .unwrap()
      .then(() => {
        
        triggerToast("Password changed successfully", 'success');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((error) => {
        triggerToast(`Failed to change password: ${error.error}`, 'error');
        
      });
  };

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <h2 className="mb-5" style={{fontSize: hdngFontSize + "px"}}>Change Password</h2>
        <Container className="bg-white rounded-2" style={{ padding: '20px' }}>
          <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="oldPassword" className="mb-3 position-relative">
              <Form.Label column sm="3" style={{ textAlign: 'right',fontSize: lblFontSize + "px" }}>Old Password</Form.Label>
              <Col >
              <div className='position-relative'>
                  <Form.Control
                    type={showOldPassword ? 'text' : 'password'}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    style={{ paddingRight: '2.5rem' }} 
                  />
                  <span
              onClick={toggleOldPasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              
            >
              {showOldPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            </div>
              </Col>
              <Col></Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="newPassword" className="mb-3 position-relative">
              <Form.Label column sm="3" style={{ textAlign: 'right',fontSize: lblFontSize + "px" }}>New Password</Form.Label>
              <Col>
              <div className='position-relative'>
              <Form.Control
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <span
              onClick={toggleNewPasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            </div></Col>
              <Col></Col>
            </Form.Group>
            <Form.Group as={Row} controlId="confirmPassword" className="mb-3 position-relative">
              <Form.Label column sm="3" style={{ textAlign: 'right', fontSize: lblFontSize + "px" }}>Confirm Password</Form.Label>
              <Col>
              <div className='position-relative'>
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              /><span
              onClick={toggleConfirmPasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            </div>
              </Col>
              <Col></Col>
            </Form.Group>

            <Row>
            <Col sm="3"></Col>
            <Col>
            <Button
              variant="primary"
              type="submit"
              className="w-25 mt-4 d-block "
              disabled={status === 'loading'}
              style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} 
            >
              {status === 'loading' ? 'Submitting...' : 'Submit'}
            </Button></Col>
            
            </Row>
            
          </Form>
        </Container>
      </main>
    </div>
  );
}

export default ChangePassword;
